import React from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

const HoursPerDayPlot = ({ data }) => {
  // Step 2: Aggregate total hours per trainer per day
  const aggregatedData = {};

  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

  data.forEach((entry) => {
    const email = entry.member_email;
    const date = entry.date; // Each entry's date

    // Sum up activity tracked time for this entry (assuming in seconds)
    const timeTrackedInHours = entry.activity_tracked_time / 3600;

    if (!aggregatedData[date]) {
      aggregatedData[date] = {};
    }
    if (!aggregatedData[date][email]) {
      aggregatedData[date][email] = 0;
    }

    // Accumulate time tracked in hours for each date and trainer (email)
    aggregatedData[date][email] += timeTrackedInHours;
  });

  // Step 3: Prepare data for Plotly
  const dates = Object.keys(aggregatedData).sort(); // Sorted dates within range
  const trainers = [...new Set(data.map((entry) => entry.member_email))];

  const traces = trainers.map((trainer) => {
    const hoursPerDay = dates.map((date) => aggregatedData[date][trainer] || 0);
    return {
      x: dates,
      y: hoursPerDay,
      type: "scatter",
      mode: "lines+markers",
      name: trainer.split('@')[0], // Trainer email or identifier
      hoverinfo: "x+y+name", // Show date, hours, and trainer on hover
        line: { color: emailColorMap[trainer] || "gray" }
    };
  });

  return (
    <Plot
      data={traces}
      layout={{
        title: `Total Hours Logged per Day`,
        yaxis: { title: "Hours Logged" },
        hovermode: "closest",
        autosize: true,
        margin: { l: 50, r: 30, t: 50, b: 100 },
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        legend: {
          orientation: 'v',               
          xanchor: 'left',    
          itemclick: 'toggleothers',
          itemdoubleclick: 'toggle'
        },
      }}
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default HoursPerDayPlot;
