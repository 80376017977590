import { Row, Col, Button, DatePicker } from "antd";
import React from "react";
import EmailSelector from "./EmailSelector";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDate } from "../../contexts/dashboardSlice";
import dayjs from "dayjs";
import useFetchDashboardData from "../../hooks/useFetchDashboardData";

const DashboardPanel = () => {
    const dispatch = useDispatch()

    const selectedDate = useSelector(state => state.dashboard.selectedDate)
    const selectedEmails = useSelector(state => state.dashboard.selectedEmails)

    const fetchData = useFetchDashboardData()

    const handleDateChange = (dates) => {
        if(dates){
          const start = dayjs.tz(`${dates[0].$y}-${dates[0].$M + 1}-${dates[0].$D} 00:00:00`, 'America/Los_Angeles').format()
          const end = dayjs.tz(`${dates[1].$y}-${dates[1].$M + 1}-${dates[1].$D} 23:59:59`, 'America/Los_Angeles').format()
    
          dispatch(setSelectedDate([start, end]))
        }else{
          dispatch(setSelectedDate([null, null]))
        }
      };

    const handleApply = () => {
        fetchData(selectedEmails, selectedDate[0], selectedDate[1])
    }

    

    return (
        <>
        <Row justify="center" align="middle">
            <Col span={18}>
                <EmailSelector />
            </Col>
        </Row>
        <Row justify="center" align="middle" style={{marginTop: 10}}>
            <Col span={6}>
            <DatePicker.RangePicker
                className="date-picker"
                format="YYYY-MM-DD"
                onChange={handleDateChange}
                superNextIcon={null}
                superPrevIcon={null}
                presets={[
                  {
                    label: 'Today',
                    value: [dayjs(), dayjs()],
                  },
                  {
                    label: 'Yesterday',
                    value: [dayjs().subtract(1, 'd'), dayjs().subtract(1, 'd')],
                  },
                  {
                    label: 'Last 7 Days',
                    value: [dayjs().add(-7, 'd'), dayjs()],
                  },
                  {
                    label: 'Last Week',
                    value: [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')],
                  },
                  {
                    label: 'This Week',
                    value: [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')],
                  },
                  {
                    label: 'Last Month',
                    value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
                  },
                  {
                    label: 'This Month',
                    value: [dayjs().startOf('month'), dayjs().endOf('month')],
                  }
                ]}
              />
            </Col>
            <Col span={4}>
                <Button onClick={handleApply}>Apply</Button>
            </Col>
        </Row>
        </>
    )
}

export default DashboardPanel;