import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Divider, Empty } from 'antd';
import dayjs from '../../utils/dayjsConfig.js';
import '../../App.css';
import ActivityCard from './ActivityCard.js';
import { ClockCircleOutlined, DashboardOutlined, SmileOutlined } from '@ant-design/icons';
import { MdOutlineKeyboard, MdOutlineMoreHoriz, MdOutlineMouse } from 'react-icons/md';
import SkeletonTimeWindow from './SkeletonTimeWindow.js';
import './CustomScrollbar.css';
import { getBaseURL, getUrlHost } from '../../utils/functions.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentVisibleDate } from '../../contexts/visibleDateSlice.js';
import useCommonHooks from '../../hooks/useCommonHooks.js';
import { useTourContext } from '../../contexts/tourContext.js';

const TimeWindowGrid = () => {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.loading.loading)
  const activities = useSelector(state => state.fetchedData.activities)
  const detailedURLMode = useSelector(state => state.settings.detailedURLMode)
  const onlySuspiciousMode = useSelector(state => state.settings.onlySuspiciousMode)

  const initialDaysNum = 2 // Number of visible days initially
  const newDaysNum = 2 // Number of new days to show on loading

  const { isTaskURL } = useCommonHooks();
  const { ref3, ref4 } = useTourContext();

  const [activitiesByDay, setActivitiesByDay] = useState([])
  const [visibleDays, setVisibleDays] = useState([])
  const [hasMore, setHasMore] = useState(true);

  const loadMoreCards = () => {
    const newVisibleCount = visibleDays.length + newDaysNum;
    setVisibleDays(Object.keys(activitiesByDay).slice(0, newVisibleCount));

    if (newVisibleCount >= Object.keys(activitiesByDay).length) {
      setHasMore(false); // Stop loading when all cards are visible
    }
  };


  const sectionRefs = useRef([]);


  const getCardVisitedURLs = (activity) => {
    const cardVisitedURLs = activity.url_details || [];
    const groupedData = cardVisitedURLs.reduce((acc, item) => {
      const isTask = isTaskURL(item.url);
      if (isTask) {
        // Extract the task ID from the URL
        const taskId = item.url.split('/tasks/')[1].split('/')[0];

        // If the taskId already exists in the accumulator, update the tracked time
        if (acc[taskId]) {
          acc[taskId].tracked += item.tracked; // Sum the tracked time
        } else {
          // Otherwise, create a new entry with the current item
          acc[taskId] = {
            id: taskId,
            url: item.url,
            title: item.title, // Take the first title
            tracked: item.tracked
          };
        }
      } else {
        if (detailedURLMode) {
          acc[item.url] = item
        } else {
          const urlHost = getUrlHost(item.url);
          if (acc[urlHost]) {
            acc[urlHost].tracked += item.tracked; // Sum the tracked time
          } else {
            // Otherwise, create a new entry with the current item
            acc[urlHost] = {
              url: getBaseURL(item.url),
              title: getUrlHost(item.url), // Take the first title
              tracked: item.tracked
            }
          }
        }
      }
      return acc;
    }, {});

    const result = Object.values(groupedData);

    const sortedResult = result.sort((a, b) => b.tracked - a.tracked);

    return sortedResult
  }

  // Throttle function to limit the rate of function execution
  const throttle = (callback, limit) => {
    let lastFunc;
    let lastRan;

    return function () {
      const context = this;
      const args = arguments;

      if (!lastRan) {
        callback.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if ((Date.now() - lastRan) >= limit) {
            callback.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  const handleScroll = throttle(() => {
    const scrollPosition = window.scrollY + 100; // Offset for sticky title
    for (let index = 0; index < sectionRefs.current.length; index++) {
      const ref = sectionRefs.current[index];
      if (ref && ref.element) {
        const { offsetTop, clientHeight } = ref.element;
        if (scrollPosition >= offsetTop && scrollPosition < offsetTop + clientHeight) {
          dispatch(setCurrentVisibleDate(ref.dayInfo))
          break;
        }
      }
    }
  }, 100);

  useEffect(() => {
    const byDayTemp = activities.reduce((acc, activity) => {
      const day = dayjs(activity.time_slot).tz(process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD');
      if (!acc[day]) {
        acc[day] = [];
      }
      if (((onlySuspiciousMode && activity.has_suspicious) || !onlySuspiciousMode)) acc[day].push(activity);
      return acc;
    }, {});

    setActivitiesByDay(byDayTemp)
    setVisibleDays(Object.keys(byDayTemp).slice(0, initialDaysNum))
    setHasMore(Object.keys(byDayTemp).length > initialDaysNum)
  }, [activities, onlySuspiciousMode])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activities]);

  return (
    <div>
      {loading ? (
        <SkeletonTimeWindow />
      ) : (
        activities.length > 0
          ?
          <InfiniteScroll
            dataLength={visibleDays.length}
            next={loadMoreCards}
            hasMore={hasMore}
            endMessage={
              !hasMore && visibleDays.length>0
                ?
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 50
                  }}
                >
                  <SmileOutlined
                    style={{
                      fontSize: 20,
                    }}
                  />
                  <p>This is the end.</p>
                </div>
                :
                null

            }
          >
            {visibleDays.length > 0 && visibleDays.map((day) => {
              const totalTracked = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.activity_tracked_time, 0);

              const overallActSeconds = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.overall_activity, 0);

              const sumMouseAct = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.mouse_percentage, 0)
              const overallMouseAct = Math.round(sumMouseAct / activitiesByDay[day].length)

              const sumKeyboardAct = activitiesByDay[day].reduce((acc, currentObject) => acc + currentObject.keyboard_percentage, 0)
              const overallKeyboardAct = Math.round(sumKeyboardAct / activitiesByDay[day].length)

              const hours = Math.floor(totalTracked / 3600);
              const minutes = Math.floor((totalTracked % 3600) / 60);
              const totalLogged = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

              const overallActPer = Math.round((overallActSeconds / totalTracked) * 100);

              return (
                <div id={day} key={day} ref={(el) => { if (el) { sectionRefs.current.push({ element: el, dayInfo: { day: day, totalLogged: totalLogged, overallActivity: overallActPer || 0, overallMouseAct: overallMouseAct || 0, overallKeyboardAct: overallKeyboardAct || 0 } }) } }}>
                  <Divider orientation="left" style={{ display: 'inline-flex', alignItems: 'center' }} plain>
                    <h3 ref={ref3} style={{ display: 'inline' }}>{dayjs(day).format('MMMM D, YYYY')}
                    </h3>
                    <span style={{ color: '#666666', fontSize: 16, fontWeight: 500, display: 'inline-flex', alignItems: 'center' }}>
                      <MdOutlineMoreHoriz style={{ marginLeft: 20 }} />
                      <ClockCircleOutlined style={{ marginLeft: 20, marginRight: 5 }} /> {totalLogged}
                      <MdOutlineMoreHoriz style={{ marginLeft: 20 }} />
                      <DashboardOutlined style={{ marginLeft: 20, marginRight: 5 }} /> {overallActPer || 0}%
                      <MdOutlineMoreHoriz style={{ marginLeft: 20 }} />
                      <span style={{ fontSize: '14px !important', display: 'inline-flex', alignItems: 'center' }}>
                        <MdOutlineMouse style={{ marginLeft: 20, marginRight: 5 }} /> {overallMouseAct || 0}%
                        <MdOutlineKeyboard style={{ marginLeft: 20, marginRight: 5 }} /> {overallKeyboardAct || 0}%
                      </span>
                    </span>
                  </Divider>
                  <Row gutter={[16, 16]} justify="center" align="top" style={{ alignItems: "stretch" }}>
                    {activitiesByDay[day].map((activity) => {
                      const cardVisitedURLs = getCardVisitedURLs(activity);

                      return (
                        <Col key={activity.time_slot} span={4}>
                          <div ref={ref4}>
                            <ActivityCard activity={activity} cardVisitedURLs={cardVisitedURLs}></ActivityCard>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              );
            })}
          </InfiniteScroll>
          :
          <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>

      )}
    </div>
  );
};

export default TimeWindowGrid;
