import { MenuOutlined } from '@ant-design/icons';
import { Col, ColorPicker, Row, Select, Tag, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEmailColorMap, setEmailOptions, setSelectedEmails } from '../../contexts/dashboardSlice.js';
import EmailListSelector from './EmailsListSelector.js';
import { IoIosColorFill } from "react-icons/io";



const colors = [
  "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A133FF", "#33FFF3", "#FFC133", "#C133FF", "#33FFBA", "#FF3333",
  "#33FFA8", "#A8FF33", "#FFA833", "#33A8FF", "#8A33FF", "#FF338A", "#33FF8A", "#338AFF", "#FF8A33", "#8AFF33",
  "#FF33FF", "#FF5733", "#33FF57", "#5733FF", "#FF33A1", "#A1FF33", "#33A1FF", "#FF33C1", "#C1FF33", "#33C1FF",
  "#FF3380", "#8033FF", "#33FF80", "#80FF33", "#FF33D1", "#D1FF33", "#33D1FF", "#FF3371", "#7133FF", "#33FF71",
  "#71FF33", "#FF3390", "#9033FF", "#33FF90", "#90FF33", "#FF33E0", "#E033FF", "#33FFE0", "#E0FF33", "#FF337F",
  "#7F33FF", "#33FF7F", "#7FFF33", "#FF339D", "#9D33FF", "#33FF9D", "#9DFF33", "#FF33BF", "#BF33FF", "#33FFBF",
  "#BFFF33", "#FF33C4", "#C433FF", "#33FFC4", "#C4FF33", "#FF33CE", "#CE33FF", "#33FFCE", "#CEFF33", "#FF33F1",
  "#F133FF", "#33FFF1", "#F1FF33", "#FF3372", "#7233FF", "#33FF72", "#72FF33", "#FF33A5", "#A533FF", "#33FFA5",
  "#A5FF33", "#FF33AA", "#AA33FF", "#33FFAA", "#AAFF33", "#FF33C2", "#C233FF", "#33FFC2", "#C2FF33", "#FF33E9",
  "#E933FF", "#33FFE9", "#E9FF33", "#FF33B4", "#B433FF", "#33FFB4", "#B4FF33", "#FF33D4", "#D433FF", "#33FFD4",
  "#D4FF33", "#FF33E7", "#E733FF", "#33FFE7", "#E7FF33", "#FF33F6", "#F633FF", "#33FFF6", "#F6FF33", "#FF33C9",
  "#C933FF", "#33FFC9", "#C9FF33", "#FF33D9", "#D933FF", "#33FFD9", "#D9FF33", "#FF33A3", "#A333FF", "#33FFA3",
  "#A3FF33", "#FF33F5", "#F533FF", "#33FFF5", "#F5FF33", "#FF3385", "#8533FF", "#33FF85", "#85FF33", "#FF33B3",
  "#B333FF", "#33FFB3", "#B3FF33", "#FF33AB", "#AB33FF", "#33FFAB", "#ABFF33", "#FF33CA", "#CA33FF", "#33FFCA",
  "#CAFF33", "#FF33EE", "#EE33FF", "#33FFEE", "#EEFF33", "#FF33DF", "#DF33FF", "#33FFDF", "#DFFF33", "#FF33FB",
  "#FB33FF", "#33FFFB", "#FBFF33", "#FF33E2", "#E233FF", "#33FFE2", "#E2FF33", "#FF33BD", "#BD33FF", "#33FFBD",
  "#BDFF33", "#FF33DB", "#DB33FF", "#33FFDB", "#DBFF33"
]


const TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const [color, setColor] = useState(label)

  const dispatch = useDispatch();
  const emailOptions = useSelector(state => state.dashboard.emailOptions)
  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleColorChange = (colorObj) => {
    const newColor = colorObj.toHexString()

    setColor(newColor)
    const newEmailOptions = emailOptions.map(obj => {
      if(obj.value===value){
        return {value: obj.value, label: newColor}
      }
      return obj
    })
    dispatch(setEmailOptions(newEmailOptions))

    let newEmailColorMap = {...emailColorMap}
    newEmailColorMap[value] = newColor
    dispatch(setEmailColorMap(newEmailColorMap))

  }

  const TagContent = (props) => {
    const { value } = props;

    if (value) {
      return (
        <Tooltip title="">
          {value.split("@")[0]}
        </Tooltip>
      )
    }
  }

  return (
    <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginInlineEnd: 4,
        textWrap: 'balance'
      }}
    >
      {
        <span>
          <ColorPicker style={{width: 5, height: 5, position: "absolute", zIndex: 1000}} format='hex' mode='single' defaultValue={color} defaultFormat='hex' onChange={handleColorChange} disabledAlpha>
            <span style={{marginRight: 2, cursor: 'pointer'}}><IoIosColorFill /></span>
          </ColorPicker>
          <TagContent value={value} label={color} handleColorChange={handleColorChange}></TagContent>
        </span>
      }
    </Tag>
  );
};

const EmailSelector = () => {

  const dispatch = useDispatch();

  const emails = useSelector(state => state.fetchedData.monitorEmails)
  const selectedEmails = useSelector(state => state.dashboard.selectedEmails)
  const emailOptions = useSelector(state => state.dashboard.emailOptions)

  useEffect(() => {
    const optionsObjs = emails.map((email, index) => {
      return ({
        value: email,
        label: colors[index % colors.length]
      })
    }
    )

    dispatch(setEmailOptions(optionsObjs))
  }, [emails])

  const handleSelectChange = (values, objsList) => {
    let emailColorMap = {}
    if (objsList) {
      emailColorMap = objsList.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
      }, {});
    }
    dispatch(setEmailColorMap(emailColorMap))
    dispatch(setSelectedEmails(values));
  }

  const handleBulkSelection = (value) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    const emails = value.match(emailRegex);

    if(emails){
      const objsList = emailOptions.filter(obj => emails.includes(obj.value))
      handleSelectChange(emails, objsList)
    }
  }

  return (
    <Row gutter={5}>
      <Col span={22}>
        <Select
          mode="multiple"
          tagRender={TagRender}
          style={{
            width: '100%'
          }}
          options={emailOptions}
          placeholder="Emails"
          onChange={handleSelectChange}
          optionRender={(option) => <span>{option.data.value}</span>}
          value={selectedEmails}
          suffixIcon={<div><MenuOutlined onClick={() => handleSelectChange(emailOptions.map(option => option.value))}></MenuOutlined></div>}
          allowClear
          showSearch
        />
      </Col>
      <Col span={2}>
        <EmailListSelector handleBulkSelection={handleBulkSelection} />
      </Col>
    </Row>
  );
}


export default EmailSelector;