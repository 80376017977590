import React from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

const ProjectsBarPlot = ({ data }) => {
  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)
  const aggregatedAverages = {};

  

  data.forEach((entry) => {
    const email = entry.member_email;
    const timeTrackedInHours = entry.activity_tracked_time / 3600;
    const projectName = entry.project_name

    if (!aggregatedAverages[email]) {
        aggregatedAverages[email] = {};
    }
    if (!aggregatedAverages[email][projectName]) {
        aggregatedAverages[email][projectName] = 0;
    }

    // Accumulate time tracked for each unique day per trainer
    aggregatedAverages[email][projectName] += timeTrackedInHours;
  });

  

  const projects = [...new Set(data.map((entry) => entry.project_name))];
  const trainers = [...new Set(data.map((entry) => entry.member_email))];

  const traces = trainers.map((trainer) => {
    const totalHoursPerProject = projects.map((project) => {
      const projectData = aggregatedAverages[trainer]?.[project];
      return projectData ? projectData : 0;
    });

    return {
      x: projects,
      y: totalHoursPerProject,
      type: "bar",
      name: trainer.split('@')[0],
      marker: { color: emailColorMap[trainer] || "gray" },
      hoverinfo: "x+y+name", // Show day, average hours, and trainer on hover
    };
  });

  return (
    <Plot
      data={traces}
      layout={{
        title: `Average Hours Logged per Day of the Week`,
        barmode: "overlay", 
        yaxis: { title: "Average Hours Logged" },
        hovermode: "closest",
        legend: {
          itemclick: 'toggleothers', 
          itemdoubleclick: 'toggle' 
        },
        autosize: true,
          margin: { l: 50, r: 30, t: 50, b: 200 },
          plot_bgcolor: "rgba(0, 0, 0, 0)",
          paper_bgcolor: "rgba(0, 0, 0, 0)",
      }}
      config={{
        responsive: true
      }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default ProjectsBarPlot;
