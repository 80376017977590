import React, { useEffect } from 'react';
import MainContainer from '../base/MainContainer.js';
import '../../App.css';
import { TourProvider } from '../../contexts/tourContext.js';
import Dashboard from './Dashboard.js';
import DashboardPanel from './DashboardPanel.js'
import useFetchMonitorEmails from '../../hooks/useFetchMonitorEmails.js';
import { useSelector } from 'react-redux';


const DashboardIndex = () => {
  const userEmail = useSelector(state => state.user.email)
  const monitorEmails = useSelector(state => state.fetchedData.monitorEmails)

  const fetchMonitorEmails = useFetchMonitorEmails();

  useEffect(() => {
    if (userEmail && monitorEmails.length==0) {
      fetchMonitorEmails(userEmail);
    }
  }, [userEmail, monitorEmails]);

  return (
    <>
      <TourProvider>
            <DashboardPanel />
            <MainContainer>
              <Dashboard />
            </MainContainer>
      </TourProvider>
      </>
  )
};

export default DashboardIndex;
