import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    monitorEmails: [],
    activities: [],
    visitedUrls: [],
    appDetails: [],
    sortedUniqueUrls: [],
    filteredActivities: []
}

const fetchedDataSlice = createSlice({
    name: "fetchedData",
    initialState,
    reducers: {
        setMonitorEmails: (state, action) => {state.monitorEmails = action.payload},
        setActivities: (state, action) => {state.activities = action.payload},
        setVisitedUrls: (state, action) => {state.visitedUrls = action.payload},
        setAppDetails: (state, action) => {state.appDetails = action.payload},
        setSortedUniqueUrls: (state, action) => {state.sortedUniqueUrls = action.payload}
    }
})

export const { setMonitorEmails, setActivities, setVisitedUrls, setAppDetails, setSortedUniqueUrls } = fetchedDataSlice.actions
export default fetchedDataSlice.reducer