
import React, { createContext, useContext, useMemo, useRef } from 'react';


export const TourContext = createContext();

export const TourProvider = ({children}) => {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const refUrlSelectorTag = useRef(null);
    const selectAllButRef = useRef(null);
    const refOverallStats = useRef(null);
    const seeMoreTagRef = useRef(null);
    const settingsRef = useRef(null)
    const flagRef = useRef(null)

    const steps = [
        {
          title: "Trainer",
          description: "Select a trainer.",
          target: () => ref1.current
        },
        {
          title: "Date range",
          description: "Select a date range.",
          target: () => ref2.current
        },
        {
          title: "Overall stats",
          description: "Here you can see the stats for the whole date range you've selected.",
          target: () => refOverallStats.current
        },
        {
          title: "Divider",
          description: "The data will be grouped by day and you can check the overall stats for each day.",
          target: () => ref3.current
        },
        {
          title: "Time window",
          description: `
          Just like on hubstaff, the data will be shown in time windows of at most 10 minutes. On this card you'll be able to see...
          `,
          target: () => ref4.current
        },
        {
          title: "OS and project name",
          description: `
          The icon on the top left corner indicates which OS was used on this time window. By its side you can check the project in which the hours were logged.
          `,
          target: () => ref5.current
        },
        {
          title: "Start/stop icon",
          description: `
          You can see if trainer started or stopped logging at the current time window by an icon near the start/end time.
          `,
          target: () => ref6.current
        },
        {
          title: "Activities bar",
          description: `
          Here you are able to to see the overall, mouse and keyboard activity percentages right away.
          `,
          target: () => ref7.current
        },
        {
          title: "Visited URLs",
          description: "On this field you'll find all urls that were visited in the given time range. You can search and select one or many to be able to see them on each time window they were visited. Note that URLs are grouped by host, apart from feather tasks that you can select individually.",
          target: () => ref8.current
        },
        {
          title: "URL tag",
          description: "If you hover the tag you would be able to see the total time spent on it for the selected date range.",
          target: () => refUrlSelectorTag.current
        },
        {
          title: "Select/deselect all",
          description: "Note you can use this button to select and deselect all URLs.",
          target: () => selectAllButRef.current
        },
        {
          title: "'See more' tag",
          description: "When many tags are selected you can hover the 'see more' tag to check all urls' ids that were selected",
          target: () => seeMoreTagRef.current
        },
        {
          title: "URL tag",
          description: "This tag means that this url was visited on this time window. The bar considers the percentage of the total time spent on this. If you want to see the total time in minutes/seconds you can hover the bar.",
          target: () => ref9.current
        },
        {
          title: "Settings",
          description: "Opening the settings panel you would see some options to filter and modify the view.",
          target: () => settingsRef.current
        },
        {
          title: "Flags",
          description: "HMS generates some flags automatically and you would be able to see it for each suspicous time window.",
          target: () => flagRef.current
        },
        {
          title: "That's it!",
          description: "Hope this tool is useful for you and let us know if you have any suggestion!"
        }
      ]

    return (
        <TourContext.Provider value={useMemo(() => ({
            steps, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, selectAllButRef, refOverallStats, seeMoreTagRef, refUrlSelectorTag, flagRef, settingsRef
        }), [])}>
          {children}
        </TourContext.Provider>
      );
}

export const useTourContext = () => useContext(TourContext);