import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

const { Content } = Layout;

const MainContainer = ({ children }) => (
  <Content
    style={{
      padding: '0 24px',
      minHeight: 400
    }}
  >
    {children}
  </Content>
);

MainContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainContainer;
