import React, { useEffect } from 'react';
import EmailDatePicker from './EmailDatePicker.js';
import StatsContainer from './StatsContainer.js';
import TimeWindowGrid from '../../components/timesheet/TimeWindowGrid.js';
import MainContainer from '../base/MainContainer.js';
import '../../App.css';
import { useDispatch, useSelector } from 'react-redux';
import useFetchDataForEmail from '../../hooks/useFetchDataForEmail.js';
import { setDatePickerValue, setSelectedDate, setSelectedEmail, setSelectedTasks } from '../../contexts/formSlice.js';
import useFetchMonitorEmails from '../../hooks/useFetchMonitorEmails.js';
import { useLocation } from 'react-router-dom';
import useCommonHooks from '../../hooks/useCommonHooks.js';
import { getUrlHost, getUrlProtocol } from '../../utils/functions.js';
import { setSortedUniqueUrls } from '../../contexts/fetchedDataSlice.js';
import dayjs from 'dayjs';

const TimesheetIndex = () => {
  const dispatch = useDispatch();

  const userEmail = useSelector(state => state.user.email)
  const featherGroupedMode = useSelector(state => state.settings.featherGroupedMode)
  const visitedUrls = useSelector(state => state.fetchedData.visitedUrls)
  const selectedEmail = useSelector(state => state.form.selectedEmail)
  const monitorEmails = useSelector(state => state.fetchedData.monitorEmails)

  const fetchMonitorEmails = useFetchMonitorEmails();
  const fetchDataForEmail = useFetchDataForEmail();
  const { isTaskURL } = useCommonHooks();


  const location = useLocation();
  

  useEffect(() => {
    if (userEmail) {
      if(monitorEmails.length==0) fetchMonitorEmails(userEmail);

      if (!selectedEmail) {
        const queryParams = new URLSearchParams(location.search);


        const email = queryParams.get('email');
        dispatch(setSelectedEmail(email))

        const startDate = queryParams.get('startDate') || null;
        const endDate = queryParams.get('endDate') || null;

        if (startDate && endDate) {
          dispatch(setSelectedDate([startDate, endDate]))
          dispatch(setDatePickerValue([dayjs(startDate.split("T")[0]), dayjs(endDate.split("T")[0])]))
        }


        if (email && startDate && endDate) fetchDataForEmail(email, startDate, endDate)
      }
    }
  }, [userEmail, monitorEmails]);

  useEffect(() => {
    dispatch(setSelectedTasks([]))
    if (visitedUrls.length > 0) {
      let uniqueURls = visitedUrls.reduce((acc, obj) => {
        const url = isTaskURL(obj.url) ? obj.url.split("/").slice(0, 5).join("/") : `${getUrlProtocol(obj.url)}//${getUrlHost(obj.url)}`
        if (acc[url]) {
          acc[url].tracked += obj.tracked;
        } else {
          acc[url] = {
            url: url,
            title: obj.title,
            tracked: obj.tracked
          };
        }

        return acc
      }, {})

      const sortedUnique = Object.values(uniqueURls).sort((a, b) => b.tracked - a.tracked);

      dispatch(setSortedUniqueUrls([...sortedUnique]))
    }
  }, [featherGroupedMode])

  return (
    <>
        <EmailDatePicker />
        <StatsContainer />
        <MainContainer>
          <TimeWindowGrid />
        </MainContainer>
      </>
  )
};

export default TimesheetIndex;
