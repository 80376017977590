import { useSelector } from 'react-redux';


const useCommonHooks = () => {
    const featherGroupedMode = useSelector(state => state.settings.featherGroupedMode)

    const isTaskURL = (url) => {
        if (featherGroupedMode) return false

        const taskIdPattern = /[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)+/;
        return url.includes("feather.openai.com/tasks") && taskIdPattern.test(url)
    }

    return {isTaskURL}
}

export default useCommonHooks;