import { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { setLoading } from '../contexts/loadingSlice.js';
import axios from '../utils/axiosConfig.js'
import { setData } from '../contexts/dashboardSlice.js';



const useFetchDashboardData = (initialEmails = [], initialStartDate = '', initialEndDate = '') => {
    const dispatch = useDispatch();

    const fetchData = useCallback( async (emails, startDate, endDate) => {
        try {
            dispatch(setLoading(true));

            const response = await axios.get(`/fetchers/query_processed_time_windows/`, {
                params: { email: emails, start_date: startDate, end_date: endDate },
                paramsSerializer: (params) => {
                    // Custom serialization to repeat `email` parameter
                    return Object.entries(params)
                      .map(([key, value]) =>
                        Array.isArray(value)
                          ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
                          : `${key}=${encodeURIComponent(value)}`
                      )
                      .join('&');
                  },
            });

            dispatch(setData(response.data))



        } catch (error) {
            dispatch(setData([]))
            console.error("Error fetching data for email:", error);
            message["error"]({
                type: "error",
                content: "There was a problem while fetching the data",
                duration: 5
            })
        } finally {
            dispatch(setLoading(false));
        }
    }, [initialEmails, initialStartDate, initialEndDate])

    return fetchData
}

export default useFetchDashboardData;