import React, { useEffect, useState } from 'react';
import { Layout, Tour } from 'antd';
import axios from '../../utils/axiosConfig.js';
import { auth, getToken } from '../../utils/firebase.js';
import { onAuthStateChanged } from 'firebase/auth';
import '../../App.css';
import { useDispatch } from 'react-redux';
import { setLoggedUser } from '../../contexts/userSlice.js';
import useFetchRole from '../../hooks/useFetchRole.js';
import { useNavigate } from 'react-router-dom';
import WelcomeContainer from './WelcomeContainer.js';
import { useTourContext } from '../../contexts/tourContext.js';

const { Content, Header } = Layout;

const App = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { steps } = useTourContext();

  const [tourOpen, setTourOpen] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);

  const checkUserRole = useFetchRole();

  const isDebug = process.env.REACT_APP_DEBUG === 'true';


  useEffect(() => { // User state change
    const handleUserStateChange = async (user) => {
      if (isDebug) {
        // In debug mode, check if we have a token in localStorage
        const token = localStorage.getItem('token');
        const debugEmail = localStorage.getItem('debugEmail');

        if (token) {
          const email = debugEmail.split('@')[0]
          dispatch(setLoggedUser({ email: debugEmail, displayName: email })) // Set a mock user in debug mode
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          await checkUserRole(token);
          setAuthComplete(true)
        }else{
          navigate('/signIn')
        }
      } else {
        // Firebase authentication flow for production mode
        if (user) {
          const { email, displayName, photoURL } = user
          dispatch(setLoggedUser({ email, displayName, photoURL }))
          try {
            const token = await getToken(user);
            // Set token in local storage
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            await checkUserRole(token);
            setAuthComplete(true)
          } catch (error) {
            console.error("Error getting token:", error);
          }
        } else {
          dispatch(setLoggedUser(null));
          localStorage.clear()
          delete axios.defaults.headers.common['Authorization'];
          navigate('/signIn')
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleUserStateChange);
    return () => unsubscribe();
  }, [isDebug]);


  return (
    <Layout style={{
      backgroundColor: "#f8f8f8",
      minHeight: "100vh"
    }}>
      <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
      <Header style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <WelcomeContainer setTourOpen={setTourOpen} />
      </Header>
      <Content style={{ padding: '48px'}}>
        {authComplete ? children : null}
      </Content>
    </Layout>
  );
};

export default App;
