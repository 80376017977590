import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentVisibleDate: null
}

const visibleDateSlice = createSlice({
    name: "visibleDate",
    initialState,
    reducers: {
        setCurrentVisibleDate : (state, action) => {state.currentVisibleDate = action.payload}
    }
})

export const { currentVisibleDate, setCurrentVisibleDate } = visibleDateSlice.actions
export default visibleDateSlice.reducer
