import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedEmails: [],
    emailColorMap: {},
    selectedDate: [],
    data: [],
    emailOptions: []
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setSelectedEmails: (state, action) => { state.selectedEmails = action.payload },
        setSelectedDate: (state, action) => { state.selectedDate = action.payload },
        setData: (state, action) => { state.data = action.payload },
        setEmailColorMap: (state, action) => { state.emailColorMap = action.payload },
        setEmailOptions: (state, action) => { state.emailOptions = action.payload }

    }
})

export const { setSelectedEmails, setSelectedDate, setData, setEmailColorMap, setEmailOptions } = dashboardSlice.actions

export default dashboardSlice.reducer