import { useCallback } from 'react';
import axios from '../utils/axiosConfig.js'
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { setUserRole } from '../contexts/userSlice.js';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase.js';



const useFetchRole = () => {
    const dispatch = useDispatch();

    const fetchData = useCallback(async () => {
        const isDebug = process.env.REACT_APP_DEBUG === 'true';
        try {
          const response = await axios.get(`/security/test/check-role`);
          dispatch(setUserRole(response.data.role));
        } catch (error) {
          if (!isDebug) {
            signOut(auth); // Sign out if we're not in debug mode
          }
          message["error"]({
            type: "error",
            content: "Error checking user role",
            duration: 5
          })
          console.error("Error checking user role:", error);
        }
      });

    return fetchData
}

export default useFetchRole;