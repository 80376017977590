import React, { useState } from "react";
import { Button, Input, Modal } from "antd";
import { LuListEnd } from "react-icons/lu";


const { TextArea } = Input;

const EmailListSelector = ({ handleBulkSelection }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState("")

    const showModal = () => {
        setValue("")
        setIsModalOpen(true);
    };

    const handleOk = () => {
        handleBulkSelection(value)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <>
        <Button onClick={showModal}>
            <LuListEnd />
        </Button>
        <Modal title="Bulk email selection" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <p>You can paste below a list of email you want to select.</p>
            <TextArea value={value} onChange={e => setValue(e.target.value)}></TextArea>
        </Modal>
        </>
    )
}

export default EmailListSelector;