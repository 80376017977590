import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedEmail: '',
    selectedDate: [null, null],
    selectedTasks: [],
    datePickerValue: []
}

const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        setSelectedEmail: (state, action) => {state.selectedEmail = action.payload},
        setSelectedDate: (state, action) => {state.selectedDate = action.payload},
        setSelectedTasks: (state, action) => {state.selectedTasks = action.payload},
        setDatePickerValue: (state, action) => {state.datePickerValue = action.payload}
    }
})

export const { setSelectedEmail, setSelectedDate, setSelectedTasks, setDatePickerValue } = formSlice.actions
export default formSlice.reducer
