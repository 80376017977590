import React from 'react';
import { Button, Image, Menu, Popover, message } from 'antd';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../../utils/firebase.js';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedUser } from '../../contexts/userSlice.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiSolidDashboard } from "react-icons/bi";
import { HiMiniPresentationChartBar } from "react-icons/hi2";





const WelcomeContainer = () => {
  const dispatch = useDispatch()

  const [, contextHolder] = message.useMessage();

  const userName = useSelector(state => state.user.name)
  const photoURL = useSelector(state => state.user.photoURL)
  const userRole = useSelector(state => state.user.userRole)


  const location = useLocation();
  const navigate = useNavigate();

  const isDebug = process.env.REACT_APP_DEBUG === 'true'; // Detect debug mode

  // Sign-out handler for both Firebase and debug mode
  const handleSignOut = () => {
    if (isDebug) {
      // In debug mode, simply clear localStorage and reset the loggedUser state
      dispatch(setLoggedUser(null));
      localStorage.removeItem('token');
      localStorage.removeItem('debugEmail');
      window.location.href = '/'; // Redirect to login or home page
    } else {
      // Firebase sign-out
      firebaseSignOut(auth)
        .then(() => {
          dispatch(setLoggedUser(null)); // Reset logged user in the context
          window.location.href = '/'; // Redirect to login or home page
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    }
  };

  const menuItems = [
    {
      label: 'Timesheet',
      key: '/',
      icon: <BiSolidDashboard />
    },
    {
      label: 'Dashboard',
      key: '/dashboard',
      icon: <HiMiniPresentationChartBar />
    }
  ]

  
  // Function to handle navigation
  const handleClick = (e) => {
    navigate(e.key);
  };

  return (
    <>
      {contextHolder} 
      <span style={{display: 'inline-flex', alignItems: 'center'}}>
      <Popover content={<p>Your role: <b>{userRole}</b></p>}>
      <Image width={40} src={photoURL} />
      </Popover>
      <p style={{color: "#a6adb5", marginLeft: 10}}>Welcome, <b>{userName}!</b></p>
      </span>
      <span style={{display: 'inline-flex', alignItems: 'center'}}>
      <Menu
        theme="dark"
        mode="horizontal"
        onClick={handleClick}
        selectedKeys={[location.pathname]}
        items={menuItems}
        style={{width: 300}}
      />
      <Button onClick={handleSignOut} type='text' danger>Sign Out</Button>
      </span>
    </>
  );
};

export default WelcomeContainer;