import { useCallback } from 'react';
import axios from '../utils/axiosConfig.js'
import { useDispatch } from 'react-redux';
import { setMonitorEmails } from '../contexts/fetchedDataSlice.js';
import { message } from 'antd';



const useFetchMonitorEmails = (initialEmail='') => {
    const dispatch = useDispatch();

    const fetchData = useCallback(async (email=initialEmail) => {
        try {
            const response = await axios.get(`/fetchers/query_team_emails/`, {
              params: { email }
            });
            dispatch(setMonitorEmails([...response.data.emails]));
          } catch (error) {
            console.error("Error fetching monitor emails:", error);
            message["error"]({
              type: "error",
              content: "There was a problem while fetching emails",
              duration: 5
            })
          }
    }, [initialEmail])

    return fetchData
}

export default useFetchMonitorEmails;