import { Col, Row, Skeleton } from "antd";
import React from "react";

const SkeletonTimeWindow = () => (
    <Row gutter={[32, 30]} style={{ marginTop: 50 }}>
        {
            Array(24).fill(0).map((_, index) => (
                <Col span={4} key={index}>
                    <Skeleton paragraph={{ rows: 4, width: ["100%", "100%", "100%", "100%"] }} block active />
                </Col>
            ))
        }
    </Row>
)

export default SkeletonTimeWindow;