import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: null,
    name: '',
    photoURL: '',
    role: ''
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoggedUser: (state, action) => {
            state.email = action.payload ? action.payload.email : null
            state.name = action.payload ? action.payload.displayName : null
            state.photoURL = action.payload ? action.payload.photoURL : null
        },
        setUserRole: (state, action) => {state.userRole = action.payload}
    }
})

export const { setLoggedUser, setUserRole } = userSlice.actions
export default userSlice.reducer
