import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../utils/firebase.js';
import axios from '../../utils/axiosConfig.js';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const isDebug = process.env.REACT_APP_DEBUG === 'true';

  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/")
    } catch (error) {
      message["error"]({
        type: "error",
        content: `Failed to login: ${error}`,
        duration: 5
      })
      console.error("Error signing in:", error);
    }
  };

  const handleDebugLogin = async ({ email, password }) => {
    try {
      const response = await axios({
        method: 'post',
        url: process.env.REACT_APP_BACKEND_BASE_URL,
        data: { email, password },
        headers: {
          'Content-Type': 'application/json',
          'X-Impersonation': 'true'
        }
      });
      
      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('debugEmail', email);
        navigate("/");
      } else {
        message["error"]({
          type: "error",
          content: `Failed to login: ${response.data.message}`,
          duration: 5
        });
      }
    } catch (error) {
      message["error"]({
        type: "error",
        content: `Login failed: ${error.response?.data?.message || error.message}`,
        duration: 5
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token) navigate("/")
  }, [])
  
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {isDebug ? (
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            style={{
              maxWidth: 600,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={handleDebugLogin}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
      ) : (
        <Button type="primary" onClick={handleSignIn}>Sign In with Google</Button>
      )}
    </div>
  );
};

export default SignIn;
