import { configureStore } from '@reduxjs/toolkit';
import formReducer, {setDatePickerValue} from '../contexts/formSlice.js'
import loadingReducer from '../contexts/loadingSlice.js'
import fetchedDataReducer from '../contexts/fetchedDataSlice.js'
import settingsReducer from '../contexts/settingsSlice.js'
import userReducer from '../contexts/userSlice.js'
import visibleDateSliceReducer from '../contexts/visibleDateSlice.js'
import dashboardSliceReducer from '../contexts/dashboardSlice.js'

export const store = configureStore({
  reducer: {
    form: formReducer,
    loading: loadingReducer,
    fetchedData: fetchedDataReducer,
    settings: settingsReducer,
    user: userReducer,
    visibleDate: visibleDateSliceReducer,
    dashboard: dashboardSliceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['form.datePickerValue'], 
        ignoredActions: [setDatePickerValue.type]
      },
    }),
});

export default store;