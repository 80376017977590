import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    detailedURLMode: true,
    onlySuspiciousMode: false,
    featherGroupedMode: false
}

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setDetailedURLMode: (state, action) => {state.detailedURLMode = action.payload},
        setOnlySuspiciousMode: (state, action) => {state.onlySuspiciousMode = action.payload},
        setFeatherGroupedMode: (state, action) => {state.featherGroupedMode = action.payload}
    }
})

export const { setDetailedURLMode, setOnlySuspiciousMode, setFeatherGroupedMode } = settingsSlice.actions
export default settingsSlice.reducer
