import React from 'react';
import DensityPlot from './plots/DensityPlot';
import { Layout, Row, Col, Skeleton, Empty, Collapse } from 'antd'
import { useSelector } from 'react-redux';
import TotalLoggedChart from './plots/TotalLoggedPlot';
import URLBoxPlot from './plots/URLBoxPlot';
import AppBoxPlot from './plots/AppBoxPlot';
import HoursPerDayPlot from './plots/HoursPerDayPlot';
import TotalLoggedDayWeekPlot from './plots/TotalLoggedDayWeekPlot';
import { DotChartOutlined } from '@ant-design/icons';
import FlagsCountPlot from './plots/FlagsCountPlot';
import ActivityPerDayPlot from './plots/ActivityPerDayPlot';
import ProjectsBarPlot from './plots/ProjectsBarPlot';

const { Content } = Layout;
const { Panel } = Collapse;

const Dashboard = () => {
  const data = useSelector(state => state.dashboard.data)
  const loading = useSelector(state => state.loading.loading)

  return (
    <Content style={{ marginTop: 50, backgroundColor: "white", padding: 20, borderRadius: 20, minHeight: 400 }}>
      {
        loading
          ?
          <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Skeleton.Node active style={{ margin: "auto" }}>
              <DotChartOutlined
                style={{
                  fontSize: 40,
                  color: '#bfbfbf',
                }}
              />
            </Skeleton.Node>
          </div>
          :
          (
            data.length > 0
              ?
              <Collapse style={{ width: "100%" }} defaultActiveKey={["hours", "density_act", "act_per_day", "urls_apps", "flags", "project"]} ghost>

                <Panel header="Hours" key={"hours"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={11} style={{ height: 400 }}>
                      <TotalLoggedChart data={data} />
                    </Col>
                    <Col span={13} style={{ height: 400 }}>
                      <HoursPerDayPlot data={data} />
                    </Col>
                    <Col span={18} style={{ height: 400 }}>
                      <TotalLoggedDayWeekPlot data={data} />
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Density Activity" key={"density_act"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={12} style={{ height: 400 }}>
                      <DensityPlot
                        data={data}
                        metric="overall_percentage"
                        title="Density of Overall Percentage"
                      />
                    </Col>
                    <Col span={12} style={{ height: 400 }}>
                      <DensityPlot
                        data={data}
                        metric="keyboard_percentage"
                        title="Density of Keyboard Percentage"
                      />
                    </Col>
                    <Col span={12} style={{ height: 400 }}>
                      <DensityPlot
                        data={data}
                        metric="mouse_percentage"
                        title="Density of Mouse Percentage"
                      />
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Activity Per day" key={"act_per_day"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={12} style={{ height: 400 }}>
                      <ActivityPerDayPlot data={data} metric='overall_activity' title='AVG activity per Day' />
                    </Col>
                    <Col span={12} style={{ height: 400 }}>
                      <ActivityPerDayPlot data={data} metric='keyboard_activity' title='AVG keyboard activity per Day' />
                    </Col>
                    <Col span={12} style={{ height: 400 }}>
                      <ActivityPerDayPlot data={data} metric='mouse_activity' title='AVG mouse activity per Day' />
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Projects" key={"project"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={20} style={{ height: 500 }}>
                      <ProjectsBarPlot data={data} />
                    </Col>
                  </Row>
                </Panel>
                

                <Panel header="URLs / APPs" key={"urls_apps"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={12} style={{ height: 400 }}>
                      <URLBoxPlot data={data} />
                    </Col>
                    <Col span={12} style={{ height: 400 }}>
                      <AppBoxPlot data={data} />
                    </Col>
                  </Row>
                </Panel>

                <Panel header="Flags" key={"flags"}>
                  <Row gutter={[5, 70]} align="middle" justify="center">
                    <Col span={20} style={{ height: 400 }}>
                      <FlagsCountPlot data={data} />
                    </Col>
                  </Row>
                </Panel>

              </Collapse>


              :
              <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
          )

      }
    </Content>
  );
};

export default Dashboard;
