import React from "react";
import Plot from "react-plotly.js";

// Utility function to extract host from URL
const extractHost = (url) => {
  try {
    return new URL(url).hostname;
  } catch {
    return url;
  }
};

const URLBoxPlot = ({ data }) => {
  // Step 1: Aggregate data to calculate total time per URL host across all trainers
  const totalTrackedTimeByHost = {};

  data.forEach((entry) => {
    entry.url_details.forEach((urlDetail) => {
      const host = extractHost(urlDetail.url);
      const timeTracked = urlDetail.tracked;

      // Accumulate total time tracked per host
      if (!totalTrackedTimeByHost[host]) {
        totalTrackedTimeByHost[host] = 0;
      }
      totalTrackedTimeByHost[host] += timeTracked;
    });
  });

  // Step 2: Identify top 20 most visited URLs by total tracked time
  const top20Hosts = Object.entries(totalTrackedTimeByHost)
    .sort(([, timeA], [, timeB]) => timeB - timeA) // Sort by tracked time descending
    .slice(0, 20) // Take top 20
    .map(([host]) => host);

  // Step 3: Aggregate total time for each host per trainer, but only for top 20 hosts
  const aggregatedData = {};

  data.forEach((entry) => {
    const email = entry.member_email;

    entry.url_details.forEach((urlDetail) => {
      const host = extractHost(urlDetail.url);
      const timeTracked = urlDetail.tracked;

      // Only process data for top 20 hosts
      if (top20Hosts.includes(host)) {
        if (!aggregatedData[host]) {
          aggregatedData[host] = {};
        }
        if (!aggregatedData[host][email]) {
          aggregatedData[host][email] = 0;
        }

        // Accumulate total time tracked per host per email
        aggregatedData[host][email] += timeTracked;
      }
    });
  });

  // Step 4: Convert accumulated time from seconds to hours for readability
  const traces = Object.keys(aggregatedData).map((host) => {
    const times = [];
    const emails = [];

    Object.keys(aggregatedData[host]).forEach((email) => {
      times.push(aggregatedData[host][email] / 3600); // Convert seconds to hours
      emails.push(email);
    });

    return {
      y: times,
      text: emails,
      name: host,
      type: "box",
      boxpoints: "outliers", // Show only outliers
      hoverinfo: "y+text",   // Show time in hours and email on hover
    };
  });



  return (
    <Plot
      data={traces}
      layout={{
        title: "Distribution of Time Spent per URL",
        yaxis: { title: "Time Tracked (hours)" },
        hovermode: "closest",
        autosize: true,
        margin: { l: 50, r: 30, t: 50, b: 100 },
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        showlegend: false
      }}
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default URLBoxPlot;
