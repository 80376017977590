import { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { setLoading } from '../contexts/loadingSlice.js';
import { setSelectedTasks } from '../contexts/formSlice.js';
import { setCurrentVisibleDate } from '../contexts/visibleDateSlice.js';
import { setActivities, setAppDetails, setSortedUniqueUrls, setVisitedUrls } from '../contexts/fetchedDataSlice.js';
import { getUrlHost, getUrlProtocol, replaceProtocol } from '../utils/functions.js';
import axios from '../utils/axiosConfig.js'
import useCommonHooks from './useCommonHooks.js';



const useFetchDataForEmail = (initialEmail = '', initialStartDate = '', initialEndDate = '') => {
    const dispatch = useDispatch();
    const { isTaskURL } = useCommonHooks();

    const activitiesTransformation = (response) => {
        const replacedProtocolActivities = response.data.map(activity => {
          const newURLs = activity.url_details.map(urlDetails => {
            urlDetails.url = replaceProtocol(urlDetails.url)
            return urlDetails
          })
          activity.url_details = newURLs
          return activity
        })
    
        dispatch(setActivities([...replacedProtocolActivities] || []));
      }
    
      const urlsTransformation = (response) => {
        const visitedUrlsData = response.data.flatMap(activity =>
          activity.url_details ? activity.url_details.map(detail => ({
            time_slot: activity.time_slot,
            url: replaceProtocol(detail.url) || '',
            title: detail.title || '',
            tracked: detail.tracked
          })) : []
        );
          
        dispatch(setVisitedUrls([...visitedUrlsData]));
    
        let uniqueURls = visitedUrlsData.reduce((acc, obj) => {
          const url = isTaskURL(obj.url) ? obj.url.split("/").slice(0, 5).join("/") : `${getUrlProtocol(obj.url)}//${getUrlHost(obj.url)}`
          if (acc[url]) {
            acc[url].tracked += obj.tracked;
          } else {
            acc[url] = {
              url: url,
              title: obj.title,
              tracked: obj.tracked
            };
          }
    
          return acc
        }, {})
    
        const sortedUnique = Object.values(uniqueURls).sort((a, b) => b.tracked - a.tracked);
    
        dispatch(setSortedUniqueUrls([...sortedUnique]))
      }
    
      const appsTransformation = (response) => {
        const appDetailsData = response.data.flatMap(activity =>
          activity.app_details ? activity.app_details.map(detail => ({
            time_slot: activity.time_slot,
            appName: detail.app_name || '',
            appTrackedtime: detail.app_tracked_time
          })) : []
        );
    
        let uniqueAPPs = appDetailsData.reduce((acc, obj) => {
          const appName = obj.appName;
    
          if (acc[appName]) {
            acc[appName].appTrackedtime += obj.appTrackedtime;
          } else {
            acc[appName] = {
              appName: obj.appName || '',
              appTrackedtime: obj.appTrackedtime
            };
          }
    
          return acc
        }, {})
    
        const sortedUniqueApps = Object.values(uniqueAPPs).sort((a, b) => b.appTrackedtime - a.appTrackedtime);
    
        dispatch(setAppDetails([...sortedUniqueApps]))
      }

    const fetchData = useCallback( async (email=initialEmail, startDate=initialStartDate, endDate=initialEndDate) => {
        try {
            dispatch(setLoading(true));
            dispatch(setSelectedTasks([]));
            dispatch(setCurrentVisibleDate(null));

            // Convert start and end date to YYYY-MM-DD format
            /*
            const formattedStartDate = startDate.split('T')[0];
            const formattedEndDate = endDate.split('T')[0];
      
            // Fetch summary data from the new endpoints
            
            const [memberMetricsResponse, proctoringMetricsResponse] = await Promise.all([
              axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/proctorings/daily_member/`, {
                params: { emails: email, start_date: formattedStartDate, end_date: formattedEndDate }
              }),
              axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/proctorings/daily_proctoring/`, {
                params: { emails: email, start_date: formattedStartDate, end_date: formattedEndDate }
              })
            ]);
      
            const memberMetrics = memberMetricsResponse.data;
            const proctoringMetrics = proctoringMetricsResponse.data;
            */


            const response = await axios.get(`/fetchers/query_processed_time_windows/`, {
                params: { email, start_date: startDate, end_date: endDate }
            });


            activitiesTransformation(response)

            urlsTransformation(response)

            appsTransformation(response)



        } catch (error) {
            console.error("Error fetching data for email:", error);
            dispatch(setActivities([]));
            dispatch(setVisitedUrls([]));
            dispatch(setSortedUniqueUrls([]))
            dispatch(setAppDetails([]))
            message["error"]({
                type: "error",
                content: "There was a problem while fetching the data",
                duration: 5
            })
        } finally {
            dispatch(setLoading(false));
        }
    }, [initialEmail, initialStartDate, initialEndDate])

    return fetchData
}

export default useFetchDataForEmail;