import React from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

const ActivityPerDayPlot = ({ data, metric, title }) => {
  // Step 2: Aggregate total hours per trainer per day
  const aggregatedData = {};

  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

  data.forEach((entry) => {
    const email = entry.member_email;
    const date = entry.date; // Each entry's date

    // Sum up activity tracked time for this entry (assuming in seconds)
    const timeTracked = entry.activity_tracked_time;
    const overallTracked = entry[metric];

    if (!aggregatedData[date]) {
      aggregatedData[date] = {};
    }
    if (!aggregatedData[date][email]) {
      aggregatedData[date][email] = {timeTracked: 0, overallTracked: 0};
    }

    // Accumulate time tracked in hours for each date and trainer (email)
    aggregatedData[date][email].timeTracked += timeTracked;
    aggregatedData[date][email].overallTracked += overallTracked;
  });

  // Step 3: Prepare data for Plotly
  const dates = Object.keys(aggregatedData).sort(); // Sorted dates within range
  const trainers = [...new Set(data.map((entry) => entry.member_email))];

  const traces = trainers.map((trainer) => {
    const overallAvg = dates.map((date) => (aggregatedData[date][trainer] && aggregatedData[date][trainer].timeTracked > 0) ? Math.round((aggregatedData[date][trainer].overallTracked/aggregatedData[date][trainer].timeTracked)*100) : 0);
    return {
      x: dates,
      y: overallAvg,
      type: "scatter",
      yaxis: 'y2', // Second y-axis for the line
      mode: "lines+markers",
      name: trainer.split('@')[0], // Trainer email or identifier
      hoverinfo: "x+y+name", // Show date, hours, and trainer on hover
        line: { color: emailColorMap[trainer] || "gray" }
    };
  });


  return (
    <Plot
      data={[...traces]}
      layout={{
        title: title,
        barmode: 'overlay',
        xaxis: { title: 'Date' },
        yaxis: { title: 'Hours logged' },
        yaxis2: {
            overlaying: 'y', // Overlay on the same x-axis
            side: 'right',
        },
        hovermode: "closest",
        autosize: true,
        margin: { l: 50, r: 0, t: 50, b: 100 },
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        legend: {
          orientation: 'v',
          x: 1.1,               
          xanchor: 'left',    
          itemclick: 'toggleothers',
          itemdoubleclick: 'toggle'
        },
      }}
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default ActivityPerDayPlot;
