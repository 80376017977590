import React, { useEffect, useState } from "react";
import { Button, Divider, Drawer, Form, Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDetailedURLMode, setOnlySuspiciousMode, setFeatherGroupedMode } from "../../contexts/settingsSlice.js";


const SettingsDrawer = ({drawerOpen, setDrawerOpen}) => {
    const dispatch = useDispatch();

    const detailedURLMode = useSelector(state => state.settings.detailedURLMode)
    const onlySuspiciousMode = useSelector(state => state.settings.onlySuspiciousMode)
    const featherGroupedMode = useSelector(state => state.settings.featherGroupedMode)
    
    
    const [detailedURLModeTemp, setDetailedURLModeTemp] = useState(detailedURLMode)
    const [onlySuspiciousModeTemp, setOnlySuspiciousModeTemp] = useState(onlySuspiciousMode)
    const [featherGroupedModeTemp, setFeatherGroupedModeTemp] = useState(featherGroupedMode)

    const onFinish = () => {
        dispatch(setDetailedURLMode(detailedURLModeTemp))
        dispatch(setOnlySuspiciousMode(onlySuspiciousModeTemp))
        dispatch(setFeatherGroupedMode(featherGroupedModeTemp))
    }

    useEffect(() => {
        setDetailedURLModeTemp(detailedURLMode)
        setOnlySuspiciousModeTemp(onlySuspiciousMode)
        setFeatherGroupedModeTemp(featherGroupedMode)
    }, [drawerOpen])

    return(
        <Drawer title="Settings" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Form
        layout='vertical'
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        onFinish={onFinish}
        >
          <Divider orientation="left" dashed plain>URLs</Divider>
          <Form.Item label='Group feather URLs (task and non-task links)'>
          <Switch checked={featherGroupedModeTemp} onChange={() => setFeatherGroupedModeTemp(!featherGroupedModeTemp)}></Switch>
          </Form.Item>
          <Form.Item label='Multiple URL tags per host'>
          <Switch checked={detailedURLModeTemp} onChange={() => setDetailedURLModeTemp(!detailedURLModeTemp)}></Switch>
          </Form.Item>
          <Divider orientation="left" dashed plain>Flags</Divider>
          <Form.Item label='Show only suspicious windows'>
          <Switch checked={onlySuspiciousModeTemp} onChange={() => setOnlySuspiciousModeTemp(!onlySuspiciousModeTemp)}></Switch>
          </Form.Item>
          <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
        </Form>
      </Drawer>
    )
}

export default SettingsDrawer;