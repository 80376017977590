import React from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

const FlagsCountPlot = ({ data }) => {
    const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

    const flagCounts = data.reduce((acc, entry) => {
    const { member_email, suspicious_findings } = entry;
    
    if (!acc[member_email]) acc[member_email] = {};
    
    // Count each type of flag within suspicious_findings
    for (const flagType of Object.keys(suspicious_findings)) {
        if (!acc[member_email][flagType]) acc[member_email][flagType] = 0;
        acc[member_email][flagType] += 1; // Assuming count represents the number of occurrences
    }
    
    return acc;
    }, {});

    // Organize data for Plotly
    const trainers = Object.keys(flagCounts);
    const flagTypes = Array.from(
    new Set(data.flatMap(entry => Object.keys(entry.suspicious_findings)))
    );

    const traces = trainers.map(trainer => ({
    x: flagTypes,
    y: flagTypes.map(flagType => flagCounts[trainer][flagType] || 0),
    name: trainer.split('@')[0],
    type: 'bar',
    marker: { color: emailColorMap[trainer] || 'gray'},
    hovertemplate: `<b>${trainer}</b><br>Flag Type: %{x}<br>Count: %{y}<extra></extra>`
    }));


  return (
    <Plot
      data={traces}
      layout={{
        title: 'Count of flags by Trainer',
        xaxis: { title: 'Flag Types' },
        yaxis: { title: 'Count of Flags' },
        hovermode: "closest",
        autosize: true,
        margin: { l: 50, r: 30, t: 50, b: 100 },
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        showlegend: true
      }}
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default FlagsCountPlot;
