import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { useSelector } from "react-redux";

const TotalLoggedChart = ({ data }) => {
  const [plotData, setPlotData] = useState([]);

  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

  useEffect(() => {
    if (data) {
      const memberTotals = data.reduce((acc, curr) => {
        const memberEmail = curr.member_email;
        const loggedHours = curr.activity_tracked_time/3600 || 0;
        acc[memberEmail] = (acc[memberEmail] || 0) + loggedHours;
        return acc;
      }, {});

      // Prepare data for Plotly
      const memberEmails = Object.keys(memberTotals);
      const totalLoggedHours = Object.values(memberTotals);

      const colors = memberEmails.map(member => emailColorMap[member] || "gray");

      const trace = {
        x: memberEmails.map(email => email.split('@')[0]),
        y: totalLoggedHours,
        type: "bar",
        marker: {
          color: colors
        },
      };

      setPlotData([trace]);
    }
  }, [data, emailColorMap]);

  return (
    <div style={{ width: "100%", height: 400 }}>
      <Plot
        data={plotData}
        layout={{
          title: "Total Logged Hours",
          xaxis: { automargin: true },
          yaxis: { title: "Total Logged Hours" },
          autosize: true,
          margin: { l: 50, r: 30, t: 50, b: 100 },
          plot_bgcolor: "rgba(0, 0, 0, 0)",
          paper_bgcolor: "rgba(0, 0, 0, 0)",
        }}
        config={{
          responsive: true
        }}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default TotalLoggedChart;
