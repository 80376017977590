import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import reduxStore from './store/index.js';
import SignIn from './components/auth/SignIn';
import TimesheetIndex from './components/timesheet';
import DashboardIndex from './components/dashboard';
import NotFound from './components/error/NotFound';
import { TourProvider } from './contexts/tourContext';
import App from './components/base/App';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <TourProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App><TimesheetIndex /></App>} />
            <Route path='/signIn' element={<SignIn />} />
            <Route path="/dashboard" element={<App><DashboardIndex /></App>} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </TourProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
