import React from "react";
import Plot from "react-plotly.js";

const AppBoxPlot = ({ data }) => {
  // Step 1: Aggregate data to calculate total time per app across all trainers
  const totalTrackedTimeByApp = {};

  data.forEach((entry) => {
    entry.app_details.forEach((appDetail) => {
      const appName = appDetail.app_name;
      const timeTracked = appDetail.app_tracked_time;

      // Accumulate total time tracked per application
      if (!totalTrackedTimeByApp[appName]) {
        totalTrackedTimeByApp[appName] = 0;
      }
      totalTrackedTimeByApp[appName] += timeTracked;
    });
  });

  // Step 2: Identify top 20 most used applications by total tracked time
  const top20Apps = Object.entries(totalTrackedTimeByApp)
    .sort(([, timeA], [, timeB]) => timeB - timeA) // Sort by tracked time descending
    .slice(0, 20) // Take top 20
    .map(([appName]) => appName);

  // Step 3: Aggregate total time for each app per trainer, but only for top 20 apps
  const aggregatedData = {};

  data.forEach((entry) => {
    const email = entry.member_email;

    entry.app_details.forEach((appDetail) => {
      const appName = appDetail.app_name;
      const timeTracked = appDetail.app_tracked_time;

      // Only process data for top 20 applications
      if (top20Apps.includes(appName)) {
        if (!aggregatedData[appName]) {
          aggregatedData[appName] = {};
        }
        if (!aggregatedData[appName][email]) {
          aggregatedData[appName][email] = 0;
        }

        // Accumulate total time tracked per app per email
        aggregatedData[appName][email] += timeTracked;
      }
    });
  });

  // Step 4: Convert accumulated time from seconds to hours for readability
  const traces = Object.keys(aggregatedData).map((appName) => {
    const times = [];
    const emails = [];

    Object.keys(aggregatedData[appName]).forEach((email) => {
      times.push(aggregatedData[appName][email] / 3600); // Convert seconds to hours
      emails.push(email);
    });

    return {
      y: times,
      text: emails,
      name: appName,
      type: "box",
      boxpoints: "outliers", // Show only outliers
      hoverinfo: "y+text",   // Show time in hours and email on hover
    };
  });



  return (
    <Plot
      data={traces}
      layout={{
        title: "Distribution of Time Spent per APP",
        yaxis: { title: "Time Tracked (hours)" },
        hovermode: "closest",
        autosize: true,
        margin: { l: 50, r: 30, t: 50, b: 100 },
        plot_bgcolor: "rgba(0, 0, 0, 0)",
        paper_bgcolor: "rgba(0, 0, 0, 0)",
        showlegend: false,
      }}
      config={{ responsive: true }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default AppBoxPlot;
