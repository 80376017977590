import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import * as d3 from "d3";
import { useSelector } from "react-redux";

const DensityPlot = ({ data, metric, title }) => {
  const [plotData, setPlotData] = useState([]);

  const emailColorMap = useSelector(state => state.dashboard.emailColorMap)

  useEffect(() => {
    if (data) {
      const members = Array.from(new Set(data.map(d => d.member_email))).sort();

      const bandwidth = 5; // Adjust bandwidth for smoothing if needed

      const traces = members.map(member => {
        const memberData = data.filter(d => d.member_email === member);
        const xValues = memberData.map(d => d[metric]);

        // Create a KDE function using d3
        const kde = kernelDensityEstimator(kernelEpanechnikov(bandwidth), xValues);
        const densityData = kde(d3.range(0, 100, 1));

        return {
          x: densityData.map(d => d[0]),
          y: densityData.map(d => d[1]),
          type: "scatter",
          mode: "lines",
          name: member.split('@')[0],
          fill: "tozeroy",
          opacity: 0.5,
          visible: true,
          line: { color: emailColorMap[member] || "gray" }
        };
      });

      setPlotData(traces);
    }
  }, [data, metric, emailColorMap]);

  // KDE functions for density estimation
  function kernelDensityEstimator(kernel, X) {
    return function(V) {
      return V.map(v => [v, d3.mean(X, x => kernel(v - x))]);
    };
  }

  function kernelEpanechnikov(bandwidth) {
    return function(v) {
      return Math.abs(v /= bandwidth) <= 1 ? 0.75 * (1 - v * v) / bandwidth : 0;
    };
  }

  return (
      <Plot
        data={plotData}
        layout={{
          title: {
            text: title,
            font: {
              size: 14, // Adjust this value to make the title smaller
            },
          },
          xaxis: { title: metric.replace("_", " ").toUpperCase() },
          legend: {
            orientation: 'v',    
            xanchor: 'left',       
            itemclick: 'toggleothers',
            itemdoubleclick: 'toggle',
            maxHeight: 0
          },
          autosize: true,
          plot_bgcolor: "rgba(0, 0, 0, 0)",
          paper_bgcolor: "rgba(0, 0, 0, 0)",
          margin: { l: 40, r: 0, t: 40, b: 50 }
        }}
        config={{ responsive: true }}
        style={{ width: "100%", height: "100%" }}
      />
  );
};

export default DensityPlot;
